import '../Main.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, Navigate } from 'react-router-dom';

import * as React from 'react';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import { Theme, ThemeProvider } from '@mui/material/styles';

import { ApplicationState } from '../../Store';
import {
    GetDefaultSubmissionOptions,
    GetStrongboxUrl,
    GetAlwaysCollectBorrowerBusinessInfo,
    actionCreators as AppSettingActions,
} from '../../Store/AppSettings';
import {
    BorrowerActionCreators,
    GetBorrowerId,
    GetBorrowerSubmissionId,
    GetBorrowerAnonymousState,
    GetAcceptedBusinessInfo,
    GetBorrowerSafeWSDetails,
} from '../../Store/Borrower';
import {
    GetTenantName,
    GetBrandConfig,
    GetBasicSettings,
    GetBackgroundImageUrl,
    IsBorrowerDisabled,
} from '../../Store/Tenant';
import { actionCreators as UIStateActions } from '../../Store/UIState';
import { actionCreators as ImportFinancialsActions } from '../../Store/ImportFinancials';
import { actionCreators as SubmissionActions } from '../../Store/Submission';
import {
    GetRequestedOptions,
    GetRequestedOptionsId,
    RetrievingShareableLinkOptions,
    RetrieveShareableLinkOptionsFailed
} from '../../Store/NewDataCollectionParameters';

import { BorrowerParametersContainer } from '../BorrowerParameters/BorrowerParametersContainer';

import { BuildModalThemeForTenant } from '../Brands/BrandThemes';
import { PortalHeader } from '../PortalHeader';
import { PortalFooter } from '../PortalFooter';

import {
    ImportFinancials,
    LenderConnectionOptions,
    LinkerModal,
    StrongboxConnectionDescriptor,
    StrongboxConnectionRequest,
    StrongboxImportRequest,
    Theme as StrongboxTheme
} from '@finagraph/strongbox-finconnect-react';

import { GetBasicBackgroundSettings } from '../../Utils/BackgroundUtils';
import { CreateBorrowerTheme } from '../../Utils/Style';
import { EventCategory, MetricsService } from '../../Utils/Metrics';
import { getDelegatedToken, GetFinancialsImportOptions } from '../../Utils/LinkUtils';
import { LogMessage, LogException, SeverityLevel } from '../../Utils/Logging';
import {
    pathConstants,
    SubmissionMetaBorrowerBusinessEmail,
    SubmissionMetaBorrowerBusinessName,
    ImportMetadataTags,
} from '../../Utils/Constants';

import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';

import {
    EntitySafeDetailResponse,
} from '../../Models/Api/strongbox.financialportal';
import { ConnectionRequestDescriptor, ConsumerMetadata } from '../../Models/Api/strongbox.models';
import { AccountingPackage } from '../../Models/Api/AccountingPackages';
import { BorrowerBackgroundSettings } from '../../Models/BorrowerBackground';
import { PortalCreateSubmissionRequestOptions } from '../../Models/PortalCreateSubmissionRequestOptions';
import { AccountingPkgPresentation } from '../SelectAccountingSystem/AccountingPkgPresentation';

import { CreateSubmission } from '../../Services/SubmissionService';
import { GetsertWorkspaceEntity } from '../../Services/WorkspaceService';

import { StrongboxTOU } from '../StrongboxTOU';

type InjectedReduxState = {
    alwaysCollectBorrowerBusinessInfo: boolean;
    welcome: string;
    backgroundImage: any;
    borrowerId: string;
    submissionId: string;
    strongboxUrl: string;
    strongboxModalThemes: StrongboxTheme | undefined;
    borrowerTheme: Theme;
    anonymousLink: boolean;
    shareableLinkOptions: PortalCreateSubmissionRequestOptions | undefined;
    shareableLinkOptionsId: number | undefined;
    retrievingShareableLinkOptions: boolean;
    retrievingSharedLinkOptionsFailed: boolean;
    defaultAnonymousSubmissionOptions: PortalCreateSubmissionRequestOptions;
    acceptedBusinessEmail?: string;
    acceptedBusinessName?: string;
    wsDetails?: EntitySafeDetailResponse;
    tenantDisabled: boolean;
}

type InjectedActionCreators = typeof UIStateActions & typeof ImportFinancialsActions & typeof BorrowerActionCreators & typeof AppSettingActions & typeof SubmissionActions;

export type BorrowerPortalChildProps = {
    welcome: string;
    backgroundImage: any;
    borrowerId: string;
    submissionId: string;
    strongboxUrl: string;
    strongboxModalThemes: StrongboxTheme | undefined;
    working: boolean;
    setIsWorking: (working: boolean) => void;
    setOverrideWorking: (override: boolean) => void;

    scrollTop?: () => void;

    importAccountingPkgAnonymous: (accountingPkg: AccountingPkgPresentation, workspaceInfoValid: boolean) => void;
    importAccountingPkg: (accountingPkg: AccountingPkgPresentation, allowAnonymous?: boolean) => void;
    submitWorkspaceInformationAndRedirectToDocs: () => void;
    createSubmissionAndRedirectDocs: (borrowerId: string) => void;
}

export type BorrowerPortalProps = {
    children: (props: BorrowerPortalChildProps) => JSX.Element;
    showTOU?: boolean;
}

type Props = BorrowerPortalProps & InjectedReduxState & InjectedActionCreators;

type PostTosLinkInfo = {
    accountingPkg: AccountingPkgPresentation;
};

enum PortalErrorState {
    none,
    failedToCreateWorkspace,
    failedToSubmit,
    failedToStartImport,
}

type PortalErrorInfo = {
    state: PortalErrorState,
    msg: string | undefined,
};

export const BorrowerPortalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        AllowHomeNavigate,
        alwaysCollectBorrowerBusinessInfo,
        welcome,
        borrowerId,
        submissionId,
        anonymousLink,
        showTOU,
        SetBorrowerFlowAccountingPkg,
        acceptedBusinessEmail,
        acceptedBusinessName,
        wsDetails,
        tenantDisabled,
        UpdateSubmissionStatus,
    } = props;

    const navigate = useNavigate();

    const [postTosLinkInfo, setPostTosLinkInfo] = React.useState<PostTosLinkInfo | undefined>(undefined);

    const [borrowerBackground, setBorrowerBackground] = React.useState<BorrowerBackgroundSettings | undefined>(undefined);
    const [backgroundKey, setBackgroundKey] = React.useState<number>(1);
    const [scaledChildWidth, setScaledChildWidth] = React.useState<number>(100);
    const [errorInfo, setErrorInfo] = React.useState<PortalErrorInfo>({
        state: PortalErrorState.none,
        msg: undefined,
    });

    const [working, setWorking] = React.useState<boolean>(false);
    const [overrideWorking, setOverrideWorking] = React.useState<boolean>(false);
    const [connectionInfo, setConnectionInfo] = React.useState<StrongboxConnectionDescriptor | undefined>(undefined);

    const [activeErrorMessage, setActiveErrorMessage] = React.useState<React.ReactElement | undefined>(undefined);

    React.useEffect(() => {
        GetBasicBackgroundSettings()
            .then((result) => {
                setBorrowerBackground(result);

            });

        AllowHomeNavigate(false);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (props.retrievingSharedLinkOptionsFailed) {
            setActiveErrorMessage((
                <>
                    <span className={'error-text'}>Something is wrong</span><br />
                    <p className={'error-text'} style={{ marginTop: '20px' }}>There has been an error retrieving the options for this request. Please contact support for additional assistance.</p>
                </>
            ))
        }
    }, [props.retrievingSharedLinkOptionsFailed])

    React.useEffect(() => {
        if (errorInfo.state !== PortalErrorState.none) {
            let messageSummary;

            switch (errorInfo.state) {
                case PortalErrorState.failedToCreateWorkspace:
                    messageSummary = 'There was an error creating your new business. Please wait a moment and try again.';
                    break;
                case PortalErrorState.failedToStartImport:

                    LogException(
                        `failedToStartImport`,
                        new Error(errorInfo.msg),
                        {
                            workspaceId: borrowerId,
                            submissionId: submissionId,
                            anonymousLink: anonymousLink,
                        }
                    );                
                    messageSummary = 'An error has occurred beginning financial import. Please wait a moment and try again.';
                    break;
                default:
                    messageSummary = 'An error occurred processing your request. Please wait a moment and try again.';

                    LogException(
                        `generalErrorBorrowerFlow`,
                        new Error(errorInfo.msg),
                        {
                            workspaceId: borrowerId,
                            submissionId: submissionId,
                            anonymousLink: anonymousLink,
                        }
                    );  
                    break;
            }

            if (!!errorInfo.msg) {
                setActiveErrorMessage((
                    <>
                        <span className={'error-text'}>{messageSummary}</span><br />
                        <p style={{ marginTop: '20px' }}>{`Additional information: ${errorInfo.msg}`}</p>
                    </>
                ));
            } else {
                setActiveErrorMessage((<span className={'error-text'}>{messageSummary}</span>));
            }
        } else {
            setActiveErrorMessage(undefined);
        }
        // errorInfo is the only thing I want in the dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [errorInfo.state])

    const handleResize = (): void => {
        if (!!borrowerBackground) {
            const backgroundWindow = document.getElementById('main-window-containing-background');
            if (!!backgroundWindow) {
                const imageRatio = borrowerBackground.imageHeight / borrowerBackground.imageWidth;
                const windowRatio = backgroundWindow.clientHeight / backgroundWindow.clientWidth;
                let actualWidth = 0;
                let actualHeight = 0;

                if (windowRatio > imageRatio) {
                    actualHeight = backgroundWindow.clientHeight;
                    actualWidth = (1 / imageRatio) * actualHeight;
                } else {
                    actualWidth = backgroundWindow.clientWidth;
                    actualHeight = imageRatio * actualWidth;
                }

                const visibleImageWidth = actualWidth + borrowerBackground.xOffset;

                // If this is true we'll have some extra space on the right of the image.
                if (visibleImageWidth < backgroundWindow.clientWidth) {
                    const visibleToBackgroundRatio = visibleImageWidth / backgroundWindow.clientWidth;

                    setScaledChildWidth(Math.floor(visibleToBackgroundRatio * 100.0));
                } else {
                    setScaledChildWidth(100);
                }

            }
        }
    }

    React.useEffect(() => {
        setBackgroundKey(backgroundKey + 1);
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // handleResize doesn't change and backgroundKey only changes within this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [borrowerBackground])

    const childProps = React.useMemo(() => {
        const result: BorrowerPortalChildProps = {
            ...props,
            working,
            setIsWorking: setWorking,
            setOverrideWorking: setOverrideWorking,
            scrollTop: (): void => {
                const scrollableContainer = document.getElementById('scrollable-content-container');
                if (!!scrollableContainer) {
                    scrollableContainer.scrollTo(0, 0);
                }
            },
            importAccountingPkgAnonymous,
            importAccountingPkg,
            submitWorkspaceInformationAndRedirectToDocs,
            createSubmissionAndRedirectDocs,
        };

        return result;

        // setWorking and setOverrideWorking won't change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, working, acceptedBusinessEmail, acceptedBusinessName]);


    // For some wierd reason it is potentially possible for backgroundImage to be null.  This will be alright,
    // it will just result in there not being a background image.

    const jtStyles = !!borrowerBackground ?
        {
            backgroundImage: `url('${borrowerBackground.backgroundImageUrl}')`,
            backgroundSize: 'cover',
            backgroundPositionX: borrowerBackground.xOffset,
            backgroundRepeat: 'no-repeat'
        } :
        {
        };

    const executeCreateSubmission = async (borrowerId: string, submissionMeta?: Map<string, string>, verificationEmail?: string): Promise<string | undefined> => {
        setErrorInfo({
            state: PortalErrorState.none,
            msg: undefined,
        });
        setWorking(true);

        // commenting the below out for now as I don't want to lose the history.  See below about
        // unusual scenario. User could go straight to documents upload page, upload documents and
        // backspace to get back to the main page. In that case it was semi-desirable to have everything
        // grouped in one submission.  Now just create a new submission every time we import. 
        //
        // With the ability to create another import on the final page, to properly process this in all
        // case we'll end up with yet more state tracking obscure conditions.  Better to just create a
        // new submission all the time.  98% of the time that will be right.

/*
        // There are a couple unusual scenarios where you could end up here and already have 
        // created a submission id. Had that happened, it would have been pulled out of session 
        // storage and in mapDispatchToProps this value would be set.  We should just reuse that 
        // value.

        if (!!props.submissionId) {
            return props.submissionId;
        }
*/

        let submissionId;

        try {
            submissionId = await CreateSubmission(
                borrowerId,
                props.shareableLinkOptionsId,
                submissionMeta,
                verificationEmail,
            );
        } catch (exception) {
            setErrorInfo({
                state: PortalErrorState.failedToSubmit,
                msg: exception.toString(),
            });
            setWorking(false);

            return undefined;
        }

        props.SetBorrowerSubmissionId(submissionId);

        return submissionId;
    }

    // Returns true if an asynchronous process to retrieve the delegated token was begun.
    // 
    // This means that we should go into the 'isWorking' state.  The processor for the 
    // completion handler of the asynchronous event will clear the isWorkingState.

    const startImport = async (accountingPackage: AccountingPackage, borrowerId: string, submissionId: string): Promise<void> => {
        if (!borrowerId) {
            return;
        }

        const logProperties = {
            workspaceId: borrowerId,
            accountingPackage: accountingPackage,
            submissionId: submissionId,
        };

        LogMessage(
            `startImport invoked`,
            SeverityLevel.Information,
            logProperties,
        );     

        setWorking(true);

        try {
            const token = await getDelegatedToken(borrowerId)

            const connectionInfo: StrongboxConnectionDescriptor = {
                accountingPackage,
                delegatedAccessToken: token,
                strongboxUri: props.strongboxUrl,
                orgId: borrowerId,
                existingConnectionId: undefined,
                submissionId,
                lenderManagedOptions: undefined,
                initiator: 'portal',
                sourceFlow: 'borrower',
            }

            if (props.shareableLinkOptions) {
                const options = props.shareableLinkOptions;

                connectionInfo.lenderManagedOptions = GetFinancialsImportOptions(options);
            }

            setOverrideWorking(true);
            setConnectionInfo(connectionInfo);

            LogMessage(
                `startImport success`,
                SeverityLevel.Information,
                logProperties,
            );     
    
        } catch (exception) {
            LogException(
                `startImport error`,
                exception,
                logProperties,
            );     
    
            setOverrideWorking(false);
            setConnectionInfo(undefined);
            setErrorInfo({
                state: PortalErrorState.failedToStartImport,
                msg: exception.toString(),
            });
        } finally {
            setWorking(false);
        }
    }

    const onlineLinkFunc = async (pkg: AccountingPkgPresentation, borrowerId: string, submissionId: string): Promise<void> => {
        MetricsService.tryTrackEvent(EventCategory.Import, 'BeginHomePage', { package: pkg.featureName });

        // Really it shouldn't be possible to get here and have pkg.featureName not actually be an accounting package descriptor.
        // AccountingPkgPresentation supports feature name being an accounting package or a string for demo types.  Demo
        // accounting package systems shouldn't be using this method, rather they should just be using a method that does nothing.

        const accountingPkg = pkg.featureName as AccountingPackage;

        if (!accountingPkg) {
            return;
        }

        await startImport(accountingPkg, borrowerId, submissionId);
    }

    const createSubmissionAndLink = async (
        borrowerId: string,
        accountingPkg: AccountingPkgPresentation,
        submissionMeta?: Map<string, string>,
        verificationEmail?: string,
    ): Promise<void> => {
        const submissionId = await executeCreateSubmission(borrowerId, submissionMeta, verificationEmail);

        if (!submissionId) {
            return;
        }

        setErrorInfo({
            state: PortalErrorState.none,
            msg: undefined,
        })

        await onlineLinkFunc(accountingPkg, borrowerId, submissionId);
    }

    const setBorrowerIdWrapper = (id: string): void => {
        props.SetBorrowerId(id);
        if (!!id) {
            MetricsService.identifyUser(id, id);
        }
    }

    async function submitWorkspaceInformationAndLink(accountingPkg: AccountingPkgPresentation): Promise<void> {
        setErrorInfo({
            state: PortalErrorState.none,
            msg: undefined,
        });
        setWorking(true);

        // First try to create the business entity, then we can begin linking.
        const id = await GetsertWorkspaceEntity(
            acceptedBusinessName || '',
            acceptedBusinessEmail || '',
        );

        let metadata: Map<string, string> | undefined = undefined;
        let verificationEmail: string | undefined = undefined;

        if (alwaysCollectBorrowerBusinessInfo && !!acceptedBusinessName && !!acceptedBusinessEmail) {
            metadata = new Map<string, string>();
            metadata.set(SubmissionMetaBorrowerBusinessName, acceptedBusinessName);
            metadata.set(SubmissionMetaBorrowerBusinessEmail, acceptedBusinessEmail);

            verificationEmail = acceptedBusinessEmail;
        }

        if (id) {
            setBorrowerIdWrapper(id);
            await createSubmissionAndLink(id, accountingPkg, metadata, verificationEmail);
        } else {
            setErrorInfo({
                state: PortalErrorState.failedToCreateWorkspace,
                msg: undefined,
            });
            setWorking(false);
        }
    }

    async function createSubmissionAndRedirectDocs(borrowerId: string): Promise<void> {
        const submissionId = await executeCreateSubmission(borrowerId);
        if (!submissionId) {
            return;
        }

        if (props.anonymousLink) {
            setErrorInfo({
                state: PortalErrorState.none,
                msg: undefined,
            })
            setWorking(false);
            navigate(pathConstants.linked);
        } else {
            setErrorInfo({
                state: PortalErrorState.none,
                msg: undefined,
            });

            setWorking(false);
            navigate(pathConstants.documents);
        }
    }

    async function submitWorkspaceInformationAndRedirectToDocs(): Promise<void> {
        // Should be a sanity check.  We shouldn't be able to get here if these two values aren't defined
        if (!!acceptedBusinessName && !!acceptedBusinessEmail) {
            setErrorInfo({
                state: PortalErrorState.none,
                msg: undefined,
            });
            setWorking(true);

            const id = await GetsertWorkspaceEntity(acceptedBusinessName, acceptedBusinessEmail);
            if (id) {
                setBorrowerIdWrapper(id);
                createSubmissionAndRedirectDocs(id);
            } else {
                setErrorInfo({
                    state: PortalErrorState.failedToCreateWorkspace,
                    msg: undefined,
                })
                setWorking(false);
            }
        }
    }

    function importAccountingPkgAnonymous(accountingPkg: AccountingPkgPresentation, workspaceInfoValid: boolean): void {
        childProps.scrollTop && childProps.scrollTop();

        SetBorrowerFlowAccountingPkg(accountingPkg.featureName);

        // Shouldn't be able to get here if these conditions aren't true so this is a sanity check
        if (props.anonymousLink) {
            // If we are not actually asking for workspace info, this will be false.  It will only be true
            // when we both want the workspace info AND it's valid.  All of these buttons should be disabled
            // if valid workspace information is not presently submitted on the workspace field components so
            // the check for worksapceInfoValid should be purely sanity check.  
            //
            // We also can't get here without accountingPkg.descriptor actually having a value.  Defaulting to
            // '' is a sanity check.

            if (workspaceInfoValid) {
                submitWorkspaceInformationAndLink(accountingPkg);
            }
        }
    }

    /**
     * 
     * @param accountingPkg
     * @param acceptedWorkspaceName There are scenarios where business information is collected in a regular shared link flow
     * as opposed to just during an anonymous flow. This value should be present in that circumstance.  Otherwise it can just
     * be an empty string.
     * @param acceptedWorkspaceEmail See the note above on acceptedWorkspaceName parameter.
     * @param allowAnonymous
     */
    function importAccountingPkg(accountingPkg: AccountingPkgPresentation, allowAnonymous?: boolean): void {
        childProps.scrollTop && childProps.scrollTop();
        SetBorrowerFlowAccountingPkg(accountingPkg.featureName);

        if ((!props.anonymousLink) || (allowAnonymous === true)) {
            // shareableLinkOptions will always have a value in a shared link scenario.
            // We can get here on the second pass through an anonymous flow if the user imported data,
            // got to the end and elected to import more accounting data, thus repeating the flow. 

            if (props.shareableLinkOptions) {
                props.SetBorrowerParametersActive(true);
                setPostTosLinkInfo({
                    accountingPkg,
                });
            } else {
                let metadata: Map<string, string> | undefined = undefined;
                let verificationEmail: string | undefined = undefined;

                if (alwaysCollectBorrowerBusinessInfo && !!acceptedBusinessEmail && !!acceptedBusinessName) {
                    metadata = new Map<string, string>();
                    metadata.set(SubmissionMetaBorrowerBusinessName, acceptedBusinessName);
                    metadata.set(SubmissionMetaBorrowerBusinessEmail, acceptedBusinessEmail);

                    verificationEmail = acceptedBusinessEmail;
                }

                createSubmissionAndLink(props.borrowerId, accountingPkg, metadata, verificationEmail);
            }
        }
    }

    const onConnected = (cxnRequest: StrongboxConnectionRequest, apiRequestParameters?: ConnectionRequestDescriptor): void => {
        LogMessage(
            `Processing portal submission for workspace: ${cxnRequest.orgId}`,
            SeverityLevel.Information,
            {
                options: !!connectionInfo?.lenderManagedOptions ? { ...connectionInfo.lenderManagedOptions } : {},
                workspace: cxnRequest.orgId,
                accountingPackage: cxnRequest.accountingPackage,
                submission: cxnRequest.submissionId
            }
        );

        const onImportStarted = (financialRecordId: string): void => {
    
            LogMessage(
                `onImportStarted invoked`,
                SeverityLevel.Information,
                {
                    workspaceId: cxnRequest.orgId,
                    accountingPackage: cxnRequest.accountingPackage,
                    submissionId: submissionId,
                    connectionId: apiRequestParameters?.id,
                    financialRecordId: financialRecordId,
                },
            );    

            MetricsService.tryTrackEvent(EventCategory.Import, 'JobCreatedHomePage', { connectionInfo });
            navigate(pathConstants.progress);
        }

        // existingConnectionId should be filled in by the generator of the onConnectedMethod. If it
        // is passed there as undefined it should go get a connection
        if (!!connectionInfo && cxnRequest.existingConnectionId) {
            let managedOptions: LenderConnectionOptions | undefined = connectionInfo.lenderManagedOptions;
            if (!managedOptions) {
                managedOptions = GetFinancialsImportOptions(
                    {
                        ...props.defaultAnonymousSubmissionOptions
                    }
                );
            }

            // Workspace details should be retrieved by the time we get here AND we should have disabled continue buttons
            // in the various modals if we're fetching so the check below on fetching is really just a sanity check.  
            // Shouldn't be possible for it to be true when we get here.

            const initialMetadata: ConsumerMetadata[] = [];
            if (!!acceptedBusinessName) {
                initialMetadata.push(new ConsumerMetadata({
                    label: ImportMetadataTags.borrowerBusinessName,
                    value: acceptedBusinessName,
                }))
            }
            if (!!acceptedBusinessEmail) {
                initialMetadata.push(new ConsumerMetadata({
                    label: ImportMetadataTags.borrowerBusinessEmail,
                    value: acceptedBusinessEmail,
                }))
            }
            if (!!wsDetails) {
                initialMetadata.push(new ConsumerMetadata({
                    label: ImportMetadataTags.sharedLinkCompanyName,
                    value: wsDetails.displayName,
                }))
                initialMetadata.push(new ConsumerMetadata({
                    label: ImportMetadataTags.sharedLinkApplicationId,
                    value: wsDetails.engagementCode
                }))
            }

            const importRequest: StrongboxImportRequest = {
                accountingPackage: cxnRequest.accountingPackage,
                lenderManagedOptions: managedOptions,
                initiator: 'portal',
                delegatedAccessToken: cxnRequest.delegatedAccessToken,
                orgId: cxnRequest.orgId,
                sourceFlow: 'borrower',
                strongboxUri: cxnRequest.strongboxUri,
                submissionId: cxnRequest.submissionId,
                connectionId: cxnRequest.existingConnectionId,
                initialMetadata,
            };
            
            const logProperties = {
                workspaceId: importRequest.orgId,
                accountingPackage: importRequest.accountingPackage,
                lenderManagedOptions: {...importRequest.lenderManagedOptions},
                submissionId: importRequest.submissionId,
                connectionId: importRequest.connectionId,
            };
    
            LogMessage(
                `ImportFinancials invoked`,
                SeverityLevel.Information,
                logProperties,
            );           

            setWorking(true);
            ImportFinancials(
                importRequest,
                (msg: string, detailedMsg: string) => {

                    LogException(
                        `ImportFinancials error`,
                        new Error(msg + ':' + detailedMsg),
                        logProperties,
                    );    
                                        
                    setErrorInfo({
                        state: PortalErrorState.failedToStartImport,
                        msg: detailedMsg
                    });
                    setOverrideWorking(false);
                    setConnectionInfo(undefined);
                },
                onImportStarted
            )
            .catch(exception => {
                console.error('Failed to start import for shareable link');
                console.error(exception);
                LogException(
                    'Failure starting import for shareable link',
                    exception,
                    {
                        options: !!connectionInfo?.lenderManagedOptions ? { ...connectionInfo.lenderManagedOptions } : {},
                        workspace: cxnRequest.orgId,
                        accountingPackage: cxnRequest.accountingPackage,
                        submission: cxnRequest.submissionId
                    }
                )
            })
            .finally(() => {
                setWorking(false);
            });

            // Do this now because we want the link dialog to disappear and have the working indicator go active and we don't 
            // need it any longer
            setOverrideWorking(false);
            setConnectionInfo(undefined);
        } else {
            console.error('There is no connection id, connectionInfo or connectionOptions is undefined in PortalSubmission:onConnected');
            console.error(connectionInfo?.lenderManagedOptions);
            console.error(connectionInfo);
            console.error(cxnRequest);

            LogException(
                'There is no connection id or connectionOptions is undefined in PortalSubmission:onConnected',
                new Error('There is no connection id or connectionOptions is undefined in PortalSubmission:onConnected'),
                {
                    options: !!connectionInfo?.lenderManagedOptions ? { ...connectionInfo.lenderManagedOptions } : {},
                    connectionInfo,
                    workspace: cxnRequest.orgId,
                    accountingPackage: cxnRequest.accountingPackage,
                    submission: cxnRequest.submissionId
                }
            )

            setOverrideWorking(false);
            setConnectionInfo(undefined);
        }
    }

    const onLinkCompleted = (success: boolean): void => {
        setOverrideWorking(false);
        setConnectionInfo(undefined);
    }

    const onPrerequisitesRequired = (cxnRequest: StrongboxConnectionRequest): void => {
        if (!cxnRequest.submissionId) {
            LogException(
                'onPrerequisitiesRequired invoked without submissionId',
                new Error('onPrerequisitiesRequired invoked without submissionId'),
                {
                    accountingPackage: cxnRequest.accountingPackage,
                    orgId: cxnRequest.orgId,
                }
            );
            console.error(`onPrerequisites invoked without submissionId, ${cxnRequest.accountingPackage}, ${cxnRequest.orgId}`);
            return;
        }

        UpdateSubmissionStatus(cxnRequest.orgId, cxnRequest.submissionId, 'ConnectionPendingUserIntervention', cxnRequest.accountingPackage);
        
        setConnectionInfo(undefined);
        navigate(pathConstants.progress);
    }

    const renderLinkDialog = (): React.ReactNode => {
        if (!connectionInfo) {
            return (<></>);
        }

        return (
            <LinkerModal
                cxnRequest={connectionInfo}
                theme={props.strongboxModalThemes}
                onCompleted={onLinkCompleted}
                onConnected={onConnected}
                onPrerequisitesRequired={onPrerequisitesRequired}
                checkAuthorizationStatus={true}
            />
        );
    }

    if (tenantDisabled) {
        return <Navigate to={pathConstants.tenantBorrowerDisabled} />
    }

    return (
       <ThemeProvider theme={props.borrowerTheme}>
            <div key={backgroundKey.toString()}>
                <Container
                    fluid
                    className={'borrower-window-container'}
                    style={jtStyles}
                    id={'main-window-containing-background'}
                >
                    <Row>
                        <Col>
                            <PortalHeader
                                borrowerFlow
                                hideMenu={true}
                                content={(
                                    <h1>{`${welcome}`}</h1>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: '35px',
                            width: `${scaledChildWidth}%`,
                        }}
                        className={`borrower-content-container control-region-borrower`}
                    >
                        <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }} className={'borrower-col'}>
                            <div className={`borrower-content`} id={'scrollable-content-container'}>
                                <LoadingIndicator
                                    centerIndicator={true}
                                    active={working && !overrideWorking}
                                    size={50}
                                    thickness={6}
                                />
                                {renderLinkDialog()}
                                <BorrowerParametersContainer
                                    accountingPkg={!!postTosLinkInfo ? postTosLinkInfo.accountingPkg : undefined}
                                    dismissModal={(cancelled: boolean) => {
                                        props.ToggleBorrowerParametersActive();

                                        if (!cancelled) {
                                            if (!!postTosLinkInfo) {
                                                let submissionMeta: Map<string, string> | undefined = undefined;
                                                let verificationEmail: string | undefined = undefined;

                                                if (!!acceptedBusinessName && !!acceptedBusinessEmail) {
                                                    submissionMeta = new Map<string, string>();
                                                    submissionMeta.set(SubmissionMetaBorrowerBusinessName,acceptedBusinessName);
                                                    submissionMeta.set(SubmissionMetaBorrowerBusinessEmail, acceptedBusinessEmail);

                                                    verificationEmail = acceptedBusinessEmail;
                                                }

                                                createSubmissionAndLink(
                                                    props.borrowerId,
                                                    postTosLinkInfo.accountingPkg,
                                                    submissionMeta,
                                                    verificationEmail
                                                );
                                            }
                                            setPostTosLinkInfo(undefined);
                                        }
                                    }}
                                />
                                {!!activeErrorMessage && (
                                    <Row className={'borrower-content-row'}>
                                        <Col>
                                            <span className={'error-text'}>{activeErrorMessage}</span>
                                        </Col>
                                    </Row>
                                )}
                                {!!props.children && props.children(childProps)}
                            </div>
                        </Col>
                    </Row>
                </Container>
                {showTOU && (
                    <PortalFooter
                        contentOverride={(<StrongboxTOU />)}
                    />
                )}
            </div>
        </ThemeProvider>
    );
}

export const BorrowerPortal = connect<InjectedReduxState, InjectedActionCreators, BorrowerPortalProps, ApplicationState>(
    (appState: ApplicationState, props: BorrowerPortalProps) => {
        const tenant = GetTenantName(appState);
        const basic = GetBasicSettings(appState);

        const brandconfig = GetBrandConfig(appState);
        const anonymousLink = GetBorrowerAnonymousState(appState);

        const businessInfo = GetAcceptedBusinessInfo(appState);

        const result = {
            anonymousLink,
            welcome: (basic && basic.connectWelcomeMessage) || '',
            backgroundImage: GetBackgroundImageUrl(appState),
            borrowerId: GetBorrowerId(appState),
            submissionId: GetBorrowerSubmissionId(appState),
            strongboxUrl: GetStrongboxUrl(appState) || '',
            strongboxModalThemes: BuildModalThemeForTenant(brandconfig),
            borrowerTheme: CreateBorrowerTheme(tenant),
            shareableLinkOptions: GetRequestedOptions(appState),
            shareableLinkOptionsId: GetRequestedOptionsId(appState),
            retrievingShareableLinkOptions: RetrievingShareableLinkOptions(appState),
            retrievingSharedLinkOptionsFailed: RetrieveShareableLinkOptionsFailed(appState),
            defaultAnonymousSubmissionOptions: GetDefaultSubmissionOptions(appState, 'shareableanonymous', true),
            alwaysCollectBorrowerBusinessInfo: GetAlwaysCollectBorrowerBusinessInfo(appState),
            acceptedBusinessEmail: businessInfo.email,
            acceptedBusinessName: businessInfo.name,
            wsDetails: GetBorrowerSafeWSDetails(appState),
            tenantDisabled: IsBorrowerDisabled(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...UIStateActions,
            ...ImportFinancialsActions,
            ...BorrowerActionCreators,
            ...AppSettingActions,
            ...SubmissionActions
        },
        dispatch
    )
)(BorrowerPortalComponent);
